import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, styled, alpha, Fab, Button, Box, useTheme } from '@mui/material';
import { AccountCircle, Delete, Padding } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Adb';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

import { RootState } from "../../../state";
import { TreePartComponent } from './tree-part';
import { useContext } from "../../utils/use-context";
import { AddTreePartModal } from './utils/AddTreePartModal';
import { HistoryBar } from "./utils/HistoryBar";
import { useState } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} component="div">
      {'Copyright © '}
      <Link color="inherit" href="https://codeloves.me">
        CodeLoves.Me
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface MenuAppBarProps {
  websiteName: string;
  user: any;
}

const MenuAppBar = ({ websiteName, user }: MenuAppBarProps) => {
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const chromosome = useContext(); // Replace this with the actual context hook
  const theme = useTheme(); // Use the theme hook

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutButtonClick = () => {
    chromosome.onLogoutButtonClick();
    handleClose();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    chromosome.onSearch(event.target.value);
  };

  return (
    <AppBar position="static" sx={{ boxShadow: 10 }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => chromosome.onPathSelect('/')}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {websiteName}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            borderRadius: 1, // Adjust this as per your theme's border radius
            backgroundColor: (theme) => alpha(theme.palette.common.white, 0.15),
            '&:hover': {
              backgroundColor: (theme) => alpha(theme.palette.common.white, 0.25),
            },
            width: '100%',
            marginLeft: '2.3em',
            marginRight: '1.2em',
          }}
        >
          {/* <Box
            sx={{
              padding: (theme) => theme.spacing(0, 2),
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SearchIcon />
          </Box> */}
          {/* <InputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchChange}
            value={searchValue}
            sx={{
              color: 'inherit',
              width: '100%',
              marginLeft: '3em',
              '& .MuiInputBase-input': {
                padding: (theme) => theme.spacing(1, 1, 1, 0),
                paddingLeft: `calc(1em + ${(theme:any) => theme.spacing(4)})`,
                transition: (theme) => theme.transitions.create('width'),
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  width: '12ch',
                  '&:focus': {
                    width: '20ch',
                  },
                },
              },
            }}
          /> */}
        </Box>
        {user && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Preferences</MenuItem>
              <MenuItem onClick={handleClose}>My Account</MenuItem>
              <MenuItem onClick={onLogoutButtonClick}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const DarkModeSwitch = ({ darkMode, setDarkMode }: { darkMode: boolean, setDarkMode: (darkMode: boolean) => void }) => {
  return <div style={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: 10,
    padding: 10,
    borderRadius: 10
  }}>
    <FormGroup>
      <FormControlLabel
        control={<MaterialUISwitch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
        label={darkMode ? 'Dark Mode' : 'Light Mode'}
      />
    </FormGroup>
  </div>;
}

export default function Home({ ui, user }: { ui: RootState["ui"], user: any }) {
  const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#3055c2',
      },
      background: {
        paper: '#f5f5f5',
        default: '#f0f0f5'
      },
    },
    typography: {
      fontFamily: 'IndieFlower',
    },
  });

  const loggedIn = !!user;
  const [addTreePartModalOpen, setAddTreePartModalOpen] = useState(false);
  const onAddClick = () => {
    setAddTreePartModalOpen(true);
  }
  const chromosome = useContext();

  const onTreePartAdd = (title: string, path: string, type: "Leaf" | "Branch" | "Blog") => {
    chromosome.onTreePartAdd("", title, path, type);
  }
  const onPageTreeVersionSelect = (pageTreeId: string) => {
    chromosome.onPageTreeVersionSelect(pageTreeId);
  }
  const onPageTreeDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this version of page tree?")) {
      chromosome.onPageTreeDelete(ui.pageTree.id);
    }
  }
  return (
    <>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Container component="main"  >
          <CssBaseline />
          <MenuAppBar {...{ ...ui, user }} />
          {loggedIn && ui.pageTreeHistory && <HistoryBar items={ui.pageTreeHistory!} onItemSelect={onPageTreeVersionSelect} selectedItem={ui.pageTree.id} />}
          {loggedIn && <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: "1em",
            color: "gray"
          }}>
            <Typography style={{ paddingRight: "1em" }}>Remove this version of page tree</Typography>
            <Button size={"small"} color="warning" aria-label="edit" onClick={onPageTreeDelete}>
              <DeleteIcon />
            </Button>
          </Box>}
          {ui.pageTree.trunk && <TreePartComponent treePart={ui.pageTree.trunk} currentPath={ui.path} loggedIn={loggedIn} state={ui} />}
          {!ui.pageTree.trunk && <Fab size="small" color="primary" aria-label="add" onClick={onAddClick}>
            <AddIcon />
          </Fab>}
          <Copyright sx={{ mt: 8, mb: 4 }} />
          <DarkModeSwitch {...{ darkMode, setDarkMode }} />
        </Container>
      </ThemeProvider>
      <AddTreePartModal open={addTreePartModalOpen} onClose={() => setAddTreePartModalOpen(false)} onTreePartAdd={onTreePartAdd} />
    </>
  );
}