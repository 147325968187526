import { v4 as uuid } from 'uuid';
import { Exception, Particle } from '@euglena/compact';
import { Chromosome } from "./chromosome";
import { TreePart, TreePartName, Leaf, Blog, Branch, PageTree, Page } from '@cdlvsm/website.backend.lib';
import { Notification, RootState } from "../../state";


const getTreePartByPath = (branch: TreePart, path: string): TreePart | undefined => {
  if (branch.path === path || path === "/" || path === "") return branch;
  if (branch._class === "Leaf") return undefined;
  if (branch.parts) {
    for (const part of branch.parts) {
      const found = getTreePartByPath(part, path);
      if (found) return found;
    }
  }
  return undefined;
}

const getFullPath = (trunk: TreePart, path: string): string => {
  if (trunk.path === path) return path;
  if (trunk._class === "Leaf") return "";
  if (trunk.parts) {
    for (const part of trunk.parts) {
      const found = getFullPath(part, path);
      if (found) return `${trunk.path}${found}`;
    }
  }
  return "";
}

const getStatePath = (trunk: TreePart, path: string): string => {
  const fullPath = getFullPath(trunk, path);
  return "/" + fullPath.split("/").filter(x => x).slice(1).join("/");
}

const isPathUnique = (branch: TreePart, path: string): boolean => {
  const found = getTreePartByPath(branch, path);
  return found === undefined;
}

const switchCase = {
  "Leaf": (title: string, path: string, pageSequenceId: string) => {
    return {
      _class: "Leaf",
      _createdAt: new Date().getTime(),
      title,
      path,
      pageSequenceId
    } as Leaf;
  },
  "Branch": (title: string, path: string) => {
    return {
      _class: "Branch",
      _createdAt: new Date().getTime(),
      title,
      path,
      parts: [],
    } as Branch;
  },
  "Blog": (title: string, path: string) => {
    return {
      _class: "Blog",
      _createdAt: new Date().getTime(),
      title,
      path,
      parts: [],
    } as Blog;
  }
}

export class UIChromosome extends Chromosome {
  async onSearch(value: string) {
    return this.handleEvent(async (state) => {
      //TODO: implement
      this.props.euglena.backend.search(value);
      return state;
    })
  }
  async onPageVersionDelete(id: string) {
    return this.handleEvent(async (state) => {
      const token = await this.props.euglena.localStorage.getToken();
      const deletedPage = await this.props.euglena.backend.deletePageById(id, token?.encryptedToken);
      if (Exception.isException(deletedPage)) {
        this.props.euglena.logger.error(deletedPage.message);
        return state;
      }
      const pageWithHistory = await this.props.euglena.backend.getPageWithHistory(state.ui.page!.sequenceId!, token?.encryptedToken);
      if (Exception.isException(pageWithHistory)) {
        this.props.euglena.logger.error(pageWithHistory.message);
        return state;
      }
      const { page, pageHistory } = pageWithHistory;
      return { ...state, ui: { ...state.ui, page, pageHistory, pages: { ...state.ui.pages, [page.id]: page } } }
    });
  }
  async onPageVersionSelect(pageId: string) {
    return this.handleEvent(async (state) => {
      const token = await this.props.euglena.localStorage.getToken();
      const page = state.ui.pages[pageId] || await this.props.euglena.backend.getPageById(pageId, token?.encryptedToken);
      if (Exception.isException(page)) {
        this.props.euglena.logger.error(page.message);
        return state;
      }
      return { ...state, ui: { ...state.ui, page } };
    });
  }
  async onPageTreeDelete(id: string) {
    return this.handleEvent(async (state) => {
      const token = await this.props.euglena.localStorage.getToken();
      const deletedPageTree = await this.props.euglena.backend.deletePageTreeById(id, token?.encryptedToken);
      if (Exception.isException(deletedPageTree)) {
        this.props.euglena.logger.error(deletedPageTree.message);
        return state;
      }
      const pageTreeWithHistory = await this.props.euglena.backend.getPageTreeWithHistory(state.domain, token?.encryptedToken!);
      if (Exception.isException(pageTreeWithHistory)) {
        this.props.euglena.logger.error(pageTreeWithHistory.message);
        return state;
      }
      const { pageTree, pageTreeHistory } = pageTreeWithHistory;
      return { ...state, ui: { ...state.ui, pageTree, pageTreeHistory } };
    })
  }
  async onPageTreeVersionSelect(pageTreeId: any) {
    return this.handleEvent(async (state) => {
      const token = await this.props.euglena.localStorage.getToken();
      const pageTree = await this.props.euglena.backend.getPageTreeById(pageTreeId, token?.encryptedToken);
      if (Exception.isException(pageTree)) {
        this.props.euglena.logger.error(pageTree.message);
        return state;
      }
      return { ...state, ui: { ...state.ui, pageTree } };
    })
  }
  async onPageLoad(pageSequenceId: string) {
    return this.handleEvent(async (state) => {
      if (state.user) {
        const token = await this.props.euglena.localStorage.getToken();
        const pageWithHistory = await this.props.euglena.backend.getPageWithHistory(pageSequenceId, token?.encryptedToken);
        if (Exception.isException(pageWithHistory)) {
          this.props.euglena.logger.error(pageWithHistory.message);
          return state;
        }
        const { page, pageHistory } = pageWithHistory;
        return {
          ...state,
          ui: {
            ...state.ui,
            page,
            pageHistory,
            pages: {
              ...state.ui.pages,
              [page.id]: page
            }
          }
        };
      } else {
        let page = state.ui.pages[pageSequenceId];
        if(!page){
          const result = await this.props.euglena.backend.getLatestPageBySequenceId(pageSequenceId);
          if (Exception.isException(result)) {
            this.props.euglena.logger.error(result.message);
            return state;
          }
          page = result;
        }
        return {
          ...state,
          ui: {
            ...state.ui,
            page,
            pages: {
              ...state.ui.pages,
              [page.id]: page
            }
          }
        };
      }
    });
  }

  async getState(): Promise<RootState> {
    return await this.props.euglena.vacuoleJs.getState();
  }
  onTreePartDelete(treePart: TreePart, deletePage: boolean = false) {
    return this.handleEvent(async (state) => {
      const trunk = state.ui.pageTree.trunk;
      const newPageTreeToBeCreated = {
        ...state.ui.pageTree,
        id: uuid(),
        _createdAt: new Date().getTime(),
      }
      if (trunk === treePart) {
        const token = await this.props.euglena.localStorage.getToken();
        const newPageTree = await this.props.euglena.backend.createPageTree({ ...newPageTreeToBeCreated, trunk: undefined }, token?.encryptedToken);
        if (Exception.isException(newPageTree)) {
          this.props.euglena.logger.error(newPageTree.message);
          return { ...state, ui: { ...state.ui, notification: new Notification(newPageTree.message, "error") } };
        }
        return { ...state, ui: { ...state.ui, path: "", pageTree: newPageTree } }
      }
      if ((Particle.is<Branch>(treePart, "Branch") || Particle.is<Blog>(treePart, "Blog")) && treePart.parts.length > 0) {
        this.props.euglena.logger.error(`Branch or Blog not empty`);
        return { ...state, ui: { ...state.ui, notification: new Notification("Branch or Blog not empty", "error") } };
      }
      const pathArray = state.ui.path.split("/");
      const parentPath = pathArray.slice(0, -1).join("/");
      const parent: Branch | Blog = (parentPath ? getTreePartByPath(trunk!, "/" + pathArray[pathArray.length - 2])! : trunk!) as Branch | Blog;
      //TODO: state injection
      parent.parts = parent.parts.filter(p => p !== treePart);
      state = { ...state, ui: { ...state.ui, path: parentPath } };
      //Update page tree in backend
      const token = await this.props.euglena.localStorage.getToken();
      const updatedPageTree = await this.props.euglena.backend.createPageTree(newPageTreeToBeCreated, token?.encryptedToken);
      if (Exception.isException(updatedPageTree)) {
        this.props.euglena.logger.error(updatedPageTree.message);
        state = { ...state, ui: { ...state.ui, notification: new Notification(updatedPageTree.message, "error") } };
        return state;
      }
      const pageTreeHistory = await this.props.euglena.backend.getPageTreeHistoryByDomain(state.domain, token?.encryptedToken);
      if (Exception.isException(pageTreeHistory)) {
        this.props.euglena.logger.error(pageTreeHistory.message);
        state = { ...state, ui: { ...state.ui, notification: new Notification(pageTreeHistory.message, "error") } };
        return state;
      }
      //Delete page in backend if leaf 
      if (Particle.is<Leaf>(treePart, "Leaf") && deletePage) {
        const deletedPage = await this.props.euglena.backend.deletePagesBySequenceId(treePart.pageSequenceId!, token?.encryptedToken);
        if (Exception.isException(deletedPage)) {
          this.props.euglena.logger.error(deletedPage.message);
          state = { ...state, ui: { ...state.ui, notification: new Notification(deletedPage.message, "error") } };
          return state;
        }
      }
      return { ...state, ui: { ...state.ui, pageTree: updatedPageTree, pageTreeHistory } };
    })
  }
  onPageContentChange(leaf: Leaf, title: string, html: string) {
    return this.handleEvent(async (state) => {
      const token = await this.props.euglena.localStorage.getToken();
      const newPage = await this.props.euglena.backend.createPage({
        _class: "Page",
        title,
        sequenceId: leaf.pageSequenceId!,
        domain: state.domain,
        html,
        id: uuid(),
        _createdAt: new Date().getTime(),
      }, token?.encryptedToken);
      if (Exception.isException(newPage)) {
        this.props.euglena.logger.error(newPage.message);
        return { ...state, ui: { ...state.ui, notification: new Notification(newPage.message, "error") } };
      }
      const pageHistory = await this.props.euglena.backend.getPageHistoryBySequenceId(leaf.pageSequenceId!, token?.encryptedToken);
      if (Exception.isException(pageHistory)) {
        this.props.euglena.logger.error(pageHistory.message);
        return { ...state, ui: { ...state.ui, notification: new Notification(pageHistory.message, "error") } };
      }
      return { ...state, ui: { ...state.ui, page: newPage, pageHistory, pages: { ...state.ui.pages, [newPage.id]: newPage } } };
    })
  }
  public onNotificationClose() {
    return this.handleEvent(async (state) => {
      return { ...state, ui: { ...state.ui, notification: undefined } };
    })
  }
  public onTreePartAdd(parentPath: string, title: string, path: string, type: TreePartName) {
    return this.handleEvent(async (state) => {
      const trunk = state.ui.pageTree.trunk;
      const createPage = async (): Promise<TreePart | Exception> => {
        const sequenceId = uuid();
        if (type === "Leaf") {
          const token = await this.props.euglena.localStorage.getToken();
          const page = await this.props.euglena.backend.createPage({
            _class: "Page",
            _createdAt: new Date().getTime(),
            html: "No Content",
            id: uuid(),
            title: title,
            sequenceId: uuid(),
            domain: state.domain
          }, token?.encryptedToken);
          if (Exception.isException(page)) {
            return page;
          }
          state.ui.pages[page.id] = page;
          return switchCase[type](title, path, sequenceId);
        }
        return switchCase[type](title, path);
      }
      if (trunk) {
        if (isPathUnique(trunk, path)) {
          const parent = getTreePartByPath(trunk, parentPath);
          if (!parent) {
            this.props.euglena.logger.error(`Parent path ${parentPath} not found`);
            return state;
          }
          if (parent?._class === "Leaf") {
            this.props.euglena.logger.error(`Parent path ${parentPath} is a leaf`);
            return state;
          }
          if (parent._class === "Blog" && type !== "Leaf") {
            this.props.euglena.logger.error(`Parent path ${parentPath} is a blog. Only leaf can be added to a blog`);
            return state;
          }

          let newPart: TreePart;

          const _newPart = await createPage();
          if (Exception.isException(_newPart)) {
            this.props.euglena.logger.error(_newPart.message);
            return state;
          }
          newPart = _newPart;
          parent.parts.push(newPart as any);
          const token = await this.props.euglena.localStorage.getToken();
          const updatedPageTree = await this.props.euglena.backend.createPageTree({
            ...state.ui.pageTree,
            id: uuid(),
            _createdAt: new Date().getTime(),
          }, token?.encryptedToken);
          if (Exception.isException(updatedPageTree)) {
            this.props.euglena.logger.error(updatedPageTree.message);
            return state;
          }
          const pageTreeHistory = await this.props.euglena.backend.getPageTreeHistoryByDomain(state.domain, token?.encryptedToken);
          if (Exception.isException(pageTreeHistory)) {
            this.props.euglena.logger.error(pageTreeHistory.message);
            return state;
          }
          return { ...state, ui: { ...state.ui, pageTree: updatedPageTree, pageTreeHistory } };
        } else {
          return { ...state, ui: { ...state.ui, notification: new Notification("Path already exists", "error") } };
        }
      } else {
        const newPart = await createPage();
        if (Exception.isException(newPart)) {
          this.props.euglena.logger.error(newPart.message);
          return state;
        }
        const token = await this.props.euglena.localStorage.getToken();
        const pageTree = await this.props.euglena.backend.createPageTree({
          _class: "PageTree",
          _createdAt: new Date().getTime(),
          trunk: newPart,
          domain: state.domain,
          id: uuid(),
        } as PageTree, token?.encryptedToken);
        if (Exception.isException(pageTree)) {
          this.props.euglena.logger.error(pageTree.message);
          return state;
        }
        const pageTreeHistory = await this.props.euglena.backend.getPageTreeHistoryByDomain(state.domain, token?.encryptedToken);
        if (Exception.isException(pageTreeHistory)) {
          this.props.euglena.logger.error(pageTreeHistory.message);
          return state;
        }
        return { ...state, ui: { ...state.ui, pageTree, pageTreeHistory } };
      }
    })
  }
  public onTreePartOrderChange(branchPath: string, newParts: TreePart[]) {
    return this.handleEvent(async (state) => {
      const trunk = state.ui.pageTree.trunk;
      if (trunk) {
        const branch = getTreePartByPath(trunk, branchPath);
        if (!branch) {
          this.props.euglena.logger.error(`Branch path ${branchPath} not found`);
          return state;
        }
        // TODO: state injection
        (branch as Branch | Blog).parts = newParts;
        const token = await this.props.euglena.localStorage.getToken();
        const updatedPageTree = await this.props.euglena.backend.createPageTree({
          ...state.ui.pageTree,
          id: uuid(),
          _createdAt: new Date().getTime(),
        }, token?.encryptedToken);
        if (Exception.isException(updatedPageTree)) {
          this.props.euglena.logger.error(updatedPageTree.message);
          return { ...state, ui: { ...state.ui, notification: new Notification(updatedPageTree.message, "error") } };
        }
        const pageTreeHistory = await this.props.euglena.backend.getPageTreeHistoryByDomain(state.domain, token?.encryptedToken);
        if (Exception.isException(pageTreeHistory)) {
          this.props.euglena.logger.error(pageTreeHistory.message);
          return { ...state, ui: { ...state.ui, notification: new Notification(pageTreeHistory.message, "error") } };
        }
        return { ...state, ui: { ...state.ui, pageTree: updatedPageTree, pageTreeHistory } };
      } else {
        this.props.euglena.logger.error(`Trunk not found`);
        return state;
      }
    })
  }

  public onPathSelect(path: string, { onlyUpdateState, reload }: { onlyUpdateState?: boolean | undefined, reload?: boolean | undefined } = { onlyUpdateState: false, reload: false }) {
    return this.handleEvent(async (state) => {
      this.props.euglena.logger.info(`onPathSelect: ${path}`);
      if (state.ui.pageTree.trunk) {
        const statePath = getStatePath(state.ui.pageTree.trunk, path);
        if (statePath) {
          if (!onlyUpdateState) {
            this.props.euglena.navigator.navigateTo(statePath, reload);
          }
          const selectedPage = getTreePartByPath(state.ui.pageTree.trunk, path);
          const title = selectedPage === state.ui.pageTree.trunk || !selectedPage ? state.ui.title : selectedPage.title;
          return { ...state, ui: { ...state.ui, path: statePath, title: `${state.ui.websiteName} | ${title}` } };
        } else {
          this.props.euglena.logger.error(`Path not found: ${path}`);
          this.props.euglena.navigator.navigateTo("/", reload);
          return { ...state, ui: { ...state.ui, path: "/" } };
        }
      } else {
        this.props.euglena.logger.error(`Trunk not found`);
        this.props.euglena.navigator.navigateTo("/", reload);
        return { ...state, ui: { ...state.ui, path: "/" } };
      }
    })
  }

  public onLoginButtonClick(username: string, password: string) {
    return this.handleEvent(async (state) => {
      //token
      const _token = await this.props.euglena.authClient.login(username, password);
      if (Exception.isException(_token)) {
        this.props.euglena.logger.error(_token.message); return state;
      }
      this.props.euglena.localStorage.saveToken(_token);
      //user
      const user = await this.props.euglena.authClient.getUser(_token.encryptedToken);
      if (Exception.isException(user)) {
        this.props.euglena.logger.error(user.message); return state;
      }
      //state
      return { ...state, user };
    })
  }

  public onLogoutButtonClick() {
    return this.handleEvent(async (state) => {
      const token = await this.props.euglena.localStorage.getToken();
      try {
        this.props.euglena.authClient.logout(token?.encryptedToken!);
      } catch (e: any) {
        this.props.euglena.logger.error(e.message);
      }
      this.props.euglena.localStorage.removeToken();
      return { ...state, user: undefined };
    })
  }
}



